import { createApp, defineAsyncComponent } from "vue";
const App = defineAsyncComponent(() => import("@/App.vue"));
import router from "@/router";
import { store, key } from "@/store";

import "vant/es/toast/style";
import "vant/es/dialog/style";
import "@vant/touch-emulator";
import { Lazyload } from "vant";

import i18n, { loadLocaleMessages } from "@/locales";
import "@/scripts/install";
import "@/assets/css/colors.scss";
import "@/assets/css/start.css";

const app = createApp(App);
app.use(i18n);
app.use(Lazyload, {
  lazyComponent: true,
  // attempt: 1,
});

const language = store.getters.languageShortName;
loadLocaleMessages(language).finally(() => {
  app.use(store, key).use(router).mount("#app");
});
