import { Module } from "vuex";
import { State } from "@/store/state";

import { CATEGORY_BTN_TYPE } from "@/utils/consts";

export interface GamesState {
  btnList: any[];
  categoryId: number;
  platformId: string | null;
  platformList: any[];
}

export const games: Module<GamesState, State> = {
  namespaced: true,
  state: {
    btnList: [],
    categoryId: CATEGORY_BTN_TYPE.total,
    platformId: null,
    platformList: [],
  },
  getters: {
    platformList: (state) => (id: string | null) => {
      if (id) return state.platformList.filter((v: any) => v.id == id);
      else return state.platformList;
    },
  },
  actions: {
    saveBtnList({ commit }, payload) {
      // setTimeout(() => {
      //   cacheManager.increaseCache(
      //     cacheManager.CacheKey.CATE_BTN_LIST,
      //     payload.btnList
      //   );
      // });
      commit("saveBtnList", payload);
    },
  },
  mutations: {
    saveBtnList(state, payload) {
      state.btnList = payload.btnList;
    },
    // setH5StartPopupShow(state, payload) {
    //   state.h5StartPopupShow = payload?.show
    //   state.h5SelectedGameId = payload?.gameId ?? null
    // },
    changeGameCollect(state, payload) {
      const gameId = payload?.gameId;
      if (!gameId) return;
      const newBtnList = state.btnList;
      newBtnList[0].list.forEach((value: any) => {
        if (value.id == gameId) value.isFavorite = "0";
      });
      state.btnList = newBtnList;
    },
    changeCategoryId(state, payload) {
      state.categoryId = payload.categoryId;
    },
    changePlatformId(state, payload) {
      state.platformId = payload.platformId;
    },
    savePlatFormList(state, payload) {
      state.platformList = payload.list;
    },
  },
};
