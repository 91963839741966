export default {
  UPDATE_USERINFO: "UPDATE_USERINFO",
  UPDATE_SITECONFIG: "UPDATE_SITECONFIG",
  UPDATE_USERTYPE: "UPDATE_USERTYPE",
  UPDATE_TOKEN: "UPDATE_TOKEN",
  UPDATE_OAUTH_CONFIG: "UPDATE_OAUTH_CONFIG",
  UPDATE_CURRENCY_LIST: "UPDATE_CURRENCY_LIST",
  UPDATE_SMS_CONFIG: "UPDATE_SMS_CONFIG",
  UPDATE_BRAND_LOGO: "UPDATE_BRAND_LOGO",
  UPDATE_BRAND_ABOUT_US: "UPDATE_BRAND_ABOUT_US",
  UPDATE_LANGUAGE_LIST: "UPDATE_LANGUAGE_LIST",
  UPDATE_USER_BALANCE: "UPDATE_USER_BALANCE",
  UPDATE_BRAND_ACTIVATE: "UPDATE_BRAND_ACTIVATE",
  UPDATE_CUSTOMER_SERVICE: "UPDATE_CUSTOMER_SERVICE",
  UPDATE_LOCALE_LANGUAGE: "UPDATE_LOCALE_LANGUAGE",
  UPDATE_TOP_BANNER_LIST: "UPDATE_TOP_BANNER_LIST",
  UPDATE_PWA_STATUS: "UPDATE_PWA_STATUS",
  UPDATE_ERROR_IP: "UPDATE_ERROR_IP",
  UPDATE_SYSTEM_MAINTENANCE: "UPDATE_SYSTEM_MAINTENANCE",
  // recharge
  RECHARGE_RECORD_UPDATE: "RECHARGE_RECORD_UPDATE",
  RECHARGE_RECORD_CONFIG_UPDATE: "RECHARGE_RECORD_CONFIG_UPDATE",
  RECHARGE_RECORD_RESET: "RECHARGE_RECORD_RESET",
  // message list
  MESSAGE_LIST_UPDATE: "MESSAGE_LIST_UPDATE",
  MESSAGE_LIST_CONFIG_UPDATE: "MESSAGE_LIST_CONFIG_UPDATE",
  MESSAGE_LIST_RESET: "MESSAGE_LIST",
  MESSAGE_LIST_SET_READ: "MESSAGE_LIST_SET_READ",
};
