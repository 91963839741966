import { State } from "@/store/state";

const userType = (state: State) => ({
  isSpecialAgent: state.userType === "special",
  isGuest: state.userType === "guest",
  isHost: state.userType === "normal",
  value: state.userType,
});
/** ------- 多语言 ------- */
// 默认找不到就用巴西语言。例如接口还没有回来的时候。
const languageId = (state: State) =>
  state.localeLangId ?? state.siteConfig?.language ?? "4";
const languageObj = (state: State, getters: any) =>
  state.languageList.find((v) => v.id === getters.languageId);
const languageShortName = (state: State, getters: any) =>
  getters.languageObj?.shortName ?? "pt-BR";
/** ------- 多语言 ------- */
const cdnUrl = (state: State) => state.siteConfig?.cdnUrl;
const imageUrl = (state: State, getters: any) => (imagePath?: string) => {
  return getters.cdnUrl && imagePath ? getters.cdnUrl + imagePath : null;
};
// app下载地址
const appDownloadUrl = (state: State) => state.siteConfig?.appDownloadUrl ?? "";
/** ------- 货币相关 ------- */
// 默认货币id
const currencyId = (state: State) => state.siteConfig?.siteCurrencyId ?? "6";
// 默认货币对象
const currencyObj = (state: State, getters: any) =>
  state.currencyList.find((v) => v.id === getters.currencyId);
// 默认货币名称
const currencyName = (state: State, getters: any) =>
  state.currencyList.find((v) => v.id === getters.currencyId)?.name ?? "";
// 指定货币id获取货币名称
const currencyNameWith = (state: State) => (currency: string) => {
  return state.currencyList.find((v) => v.id === currency)?.name ?? "";
};
// 默认货币小数位数
const currencyDecimalPlaces = (state: State, getters: any) =>
  Number(
    state.currencyList.find((v) => v.id === getters.currencyId)
      ?.decimalPlaces ?? "2"
  );
// 指定货币icon
const currencyIcon = (state: State) => (currency: string) =>
  state.currencyList.find((v) => v.id === currency)?.icon ?? "";
// 根据货币id获取货币小数位数
const specifiedDecimalPlaces =
  (state: State, getters: any) => (currencyId: string) => {
    const temp = state.currencyList.find(
      (v) => v.id === currencyId
    )?.decimalPlaces;
    if (temp) {
      return Number(temp);
    } else {
      return getters.currencyDecimalPlaces;
    }
  };
/** ------- 货币相关 ------- */
/** ------- 品牌相关 ------- */
// 平台logo
const brandLogo = (state: State, getters: any) =>
  getters.imageUrl(state.brandConfig?.logo);
const isAboutUsOpen = (state: State) => state.brandAboutUs?.status === "1";
/** ------- 品牌相关 ------- */
/** ------- 语言相关 ------- */
const languageItem = (state: State) => (languageId: string) => {
  return state.languageList.filter((v) => v.id == languageId)[0];
};
/** ------- 语言相关 ------- */
// 导航栏分类菜单
const gameNavigation = (state: State) => state.siteConfig?.gameNavigation ?? [];
/** ------- 客服相关 ------- */
const customerCenterOpen = (state: State) =>
  state.customerConfig?.customerServiceCenterStatus === "1";
const customerOnlineOpen = (state: State) =>
  state.customerConfig?.customerServiceOnlineStatus === "1";
const customerPopupShow = (state: State, getters: any) =>
  getters.customerCenterOpen && state.serviceShow;
const customerSildebarShow = (state: State) =>
  state.customerConfig?.customerFloatingButtonStatus == "1";
/** ------- 客服相关 ------- */

export default {
  userType,
  languageId,
  languageObj,
  languageShortName,
  cdnUrl,
  imageUrl,
  currencyId,
  currencyObj,
  currencyName,
  currencyNameWith,
  currencyDecimalPlaces,
  specifiedDecimalPlaces,
  brandLogo,
  isAboutUsOpen,
  languageItem,
  gameNavigation,
  customerCenterOpen,
  customerOnlineOpen,
  customerPopupShow,
  customerSildebarShow,
  currencyIcon,
  appDownloadUrl,
};
